<template>
  <v-container>
    <base-material-card icon="mdi-truck" title="Rutas" class="px-5 py-3">
      <v-row>
        <v-col cols="2">
          <v-btn color="primary" @click="$router.push({name: `${nameSection}Create`})">
            Nuevo
          </v-btn>
        </v-col>
        <v-col cols="10">
          <v-row>
            <v-col cols="6">
              <custom-select
                url="/vendedores/all" text="razon_social"
                label="Vendedor" :required="false"
                @change="
                  (value) => {
                    form.vendedor_id = value;
                    loadRutas();
                  }
                "
                :valor="form.vendedor_id"
                :first-select="true"
                first-text="Todos"
              ></custom-select>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Nombre de la ruta" v-model="form.nombre"
                            @keyup="loadRutas"
                            class="">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.dias"
                chips
                deletable-chips
                multiple
                label="Dias de Visitas"
                item-text="nombre"
                item-value="id"
                :items="dias"
                @change="loadRutas()"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="rutas"
                        item-key="id" hide-default-footer disable-pagination class="elevation-1">
            <template v-slot:item.dias="{item}">
                            <span v-if="item.dias && item.dias.length">
                                {{ item.dias.map(item => item.nombre).join(', ') }}
                            </span>
            </template>
            <template v-slot:item.accciones="{item}">
              <v-btn fab icon x-small
                     @click="$router.push({name: `${nameSection}Edit`,params: {id: item.id}})">
                <v-icon>far fa-edit</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination v-model="pagination.currentPage"
                        class="my-4" :length="pagination.lastPage"
                        @input="loadRutas" :total-visible="10"
                        color="primary"></v-pagination>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";

export default {
  components: {
    CustomSelect,
  },
  data: () => ({
    nameSection: "Rutas",
    headers: [
      {text: 'Nombre', value: 'nombre', sortable: false},
      {text: 'Vendedor', value: 'vendedor.razon_social', sortable: false},
      {text: 'Dias', value: 'dias', sortable: false},
      {text: 'Acciones', value: 'accciones', sortable: false},
    ],
    rutas: [],
    form: {
      dias: [],
      vendedor_id:'',
      nombre:''
    },
    pagination: {
      perPage: 10,
      lastPage: 1,
      currentPage: 1
    },
    dias: [
      {
        id: 1, nombre: 'Domingo'
      },
      {
        id: 2, nombre: 'Lunes'
      },
      {
        id: 3, nombre: 'Martes'
      },
      {
        id: 4, nombre: 'Miercoles'
      },
      {
        id: 5, nombre: 'Jueves'
      },
      {
        id: 6, nombre: 'Viernes'
      },
      {
        id: 7, nombre: 'Sabado'
      }
    ]
  }),
  mounted() {
    this.loadRutas()
  },
  methods: {
    loadRutas() {
      this.$http.get('/rutas/', {
        params: {
          ...this.form,
          page: this.pagination.currentPage
        }
      }).then(response => {
        this.rutas = response.data.data;
        this.pagination.lastPage = response.data.last_page;
        this.pagination.currentPage = response.data.current_page;
      })
    }
  }
}
</script>
